import { preload } 		from './modules/preload'
// import { nav } 			from './modules/nav'
import { slider } 		from './modules/slider'
// import { aos } 			from './modules/m_aos.js'

window.onload = function () {
	preload()
	// nav()
	slider()
	// aos()
}
